<template>
  <div>
    <v-autocomplete
        :ref="refer"
        :no-data-text="$t('common.fields.noDataAvailable')"
        color="primary"
        item-color="primary"
        :clearable="clearable"
        :items="
      translateProcessDataAdd(object.structure.dataToSelect, object.structure)
    "
        v-model="object.recordStructureValue.record_value"
        @change="$emit('change:data-selected', object.recordStructureValue.record_value)"
        @update:search-input="$emit('update:search-input', $event)"
        item-text="name"
        item-value="name"
        small-chips
        persistent-hint
        multiple
        :class="rules ? object.structure.is_required === true ? 'required' : '' : ''"
        :rules="rules ? object.structure.is_required === true ? selectRule : []: []"
    >
      <!-- TODO: Condicion para validar la cantidad de elementos dentro del selector -->
      <template v-slot:append-item>
        <slot name="append-item"></slot>
      </template>
      <template v-slot:label>
        {{ translate(object.structure.language_key, true) }}
        <span v-if="object.structure.is_required" class="red--text"
        ><strong>*</strong></span
        >
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip small v-if="index === 0">
          <span>{{ item.name | truncate(15) }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text caption"
        >(+{{ object.recordStructureValue.record_value.length - 1 }}
        {{ $t('common.titles.others') }})</span
        >
      </template>
      <template v-slot:item="{ item, attrs }">
        <div style="max-width: 360px">
          <v-checkbox
              :input-value="attrs.inputValue"
              color="primary"
              class="pt-2 pb-2 mt-0"
              :hide-details="true"
          >
            <template v-slot:label>
              <read-more
                  :classes="'mb-0'"
                  :text="item.name"
                  :max-chars="80"
                  :font-size="14"
              />
            </template>
          </v-checkbox>
        </div>
      </template>
      <template v-slot:prepend-item>
        <v-checkbox
            v-model="checkboxState"
            color="primary"
            class="pt-2 pb-2 mt-0 pl-4"
            :hide-details="true"
            @change="selectAll"
        >
          <template v-slot:label>
            <v-btn small
                   color="primary" x-small
                   text>
              {{$t('common.titles.selectAll')}}
            </v-btn>
          </template>
        </v-checkbox>
        <v-divider class="mb-2"></v-divider>
      </template>
    </v-autocomplete>
    <slot name="selected-items"></slot>
  </div>
</template>

<script>
  import ReadMore from '../../../components/ReadMoreComponent'
  import _ from 'lodash'
  import LanguajeService from '../../../services/LanguajeService'
  import sortList from '../../../services/OrderListService'
  import { mapGetters } from 'vuex'

  export default {
    name: 'NomenclatorComponent',
    components: { ReadMore },

    props: {
      object: {
        type: Object,
        required: true,
      },
      refer: {
        type: String,
        required: true,
      },
      clearable: {
        type: Boolean,
        required: false,
      },
      returnObject: {
        type: Boolean,
        required: false,
      },
      rules: {
        type: Boolean,
        required: false
      }
    },

    data() {
      return {
        checkboxState: false,
      }
    },

    computed: {
      ...mapGetters({
        selectRule: 'general/selectRule',
      }),
    },

    methods: {
      checkState() {
        if ( this.object.recordStructureValue.record_value.length == this.$refs[this.refer].filteredItems.length
        ) {
          this.checkboxState = true
        } else {
          this.checkboxState = false
        }
      },

      selectAll() {
        if (this.checkboxState === false) {
          this.object.recordStructureValue.record_value = []
        } else {
          let aux = []
          if (this.object.recordStructureValue.record_value.length > 0) {
            const names = this.getByProperty(this.$refs[this.refer].filteredItems, 'name')
            _.forEach(names, (name) => {
              if (!this.object.recordStructureValue.record_value.includes(name))
                this.object.recordStructureValue.record_value.push(name)
            })
            aux = this.object.recordStructureValue.record_value
          } else {
            aux = this.getByProperty(this.$refs[this.refer].filteredItems, 'name')
          }

          this.object.recordStructureValue.record_value = aux
        }
        this.$emit('change:data-selected', this.object.recordStructureValue.record_value)
      },

      getByProperty(array, property) {
        return _.map(array, property)
      },

      translate(key) {
        return LanguajeService.getKey3(key)
      },

      translateProcessDataAdd(data, structure) {
        if (['SEASON', 'SEASONSIMPLE'].includes(structure.structure_type)) {
          return _.map(data, details => {
            return {
              ...details,
              name: LanguajeService.getKey3(details.name),
            }
          }).sort((a, b) => {
            let nameA = a['name']
            let nameB = b['name']
            if (!isNaN(nameA.split(' ')[0]) && !isNaN(nameB.split(' ')[0])) {
              return (
                parseInt(nameA.split(' ')[0]) - parseInt(nameB.split(' ')[0])
              )
            }
            return nameA.localeCompare(nameB)
          })
        } else {
          return sortList.orderListByUppercase(
            _.map(data, details => {
              return {
                ...details,
                name: LanguajeService.getKey3(details.name),
              }
            }),
            'name'
          )
        }
      },
    },
  }
</script>

<style scoped></style>
