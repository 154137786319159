<template>
  <div>
    <v-text-field
      v-bind="textFieldProps"
      :value="formattedDisplayValue"
      @click="dialogDate = true"
      @click:clear="handleClear"
      :rules="rules"
    >
      <template v-slot:label>
        {{ label }}
        <span v-if="required" class="red--text"
        ><strong>*</strong></span
        >
      </template>
    </v-text-field>

    <!-- Diálogo para el selector de fecha -->
    <v-dialog v-model="dialogDate" persistent width="320px">
      <v-card class="d-flex justify-center flex-column">
        <v-date-picker v-bind="datePickerProps" v-model="internalDate" no-title scrollable></v-date-picker>
        <v-card-actions class="white">
          <v-btn text @click="handleClear">{{ $t('common.buttons.clean')}}</v-btn>
          <v-spacer />
          <v-btn text @click="dialogDate = false">{{ $t('common.buttons.close')}}</v-btn>
          <v-btn text color="primary" @click="handleConfirmDate">{{ $t('common.buttons.continue')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo para el selector de tiempo -->
    <v-dialog v-model="dialogTime" persistent width="320px">
      <v-card class="d-flex justify-center flex-column">
        <v-time-picker v-bind="timePickerProps" v-model="internalTime" no-title scrollable :key="timePickerKey" ></v-time-picker>
        <v-card-actions class="white">
          <v-btn text @click="handleClear">{{ $t('common.buttons.clean')}}</v-btn>
          <v-spacer />
          <v-btn text @click="dialogTime = false">{{ $t('common.buttons.close')}}</v-btn>
          <v-btn text :disabled="!internalTime" color="primary" @click="handleConfirmTime">{{ $t('common.buttons.confirm')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import LanguajeService from "@/services/LanguajeService";

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: 'dd / MM / yyyy HH:mm',
    },
    textFieldProps: {
      type: Object,
      default: () => ({}),
    },
    datePickerProps: {
      type: Object,
      default: () => ({}),
    },
    timePickerProps: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogDate: false,
      dialogTime: false,
      internalDate: this.value ? this.value.split(' ')[0] : null,
      internalTime: this.value ? this.value.split(' ')[1] : null,
      timePickerKey: 0,
    };
  },
  computed: {
    formattedDisplayValue() {
      if (this.internalDate && this.internalTime) {
        const date = new Date(`${this.internalDate}T${this.internalTime}`);
        return format(date, this.format);
      } else if (this.internalDate) {
        const date = new Date(`${this.internalDate}`);
        return format(date, this.format.split(' ')[0]);
      }
      return '';
    },
  },
  methods: {
    handleClear() {
      this.internalDate = null;
      this.internalTime = null;
      this.dialogDate = false;
      this.dialogTime = false;
      this.$emit('input', '');
    },

    handleConfirmDate() {
      if (this.internalDate) {
        this.internalTime = null;
        this.timePickerKey++;
        this.dialogDate = false;
        this.dialogTime = true;
      }
    },

    handleConfirmTime() {
      if (this.internalTime) {
        this.dialogTime = false;
        this.$emit('input', `${this.internalDate} ${this.internalTime}`);
      }
    },
    translate(key) {
      return LanguajeService.getKey3(key)
    },
  },
  watch: {
    value(val) {
      if (val) {
        // Aquí se espera que val sea del formato '2024-03-13T00:00:00.000'
        const dateTime = new Date(val); // Esto debería crear un objeto Date válido
        this.internalDate = format(dateTime, 'yyyy-MM-dd'); // Formatea la fecha para Vuetify DatePicker
        this.internalTime = format(dateTime, 'HH:mm'); // Formatea la hora para Vuetify TimePicker
      } else {
        this.internalDate = null;
        this.internalTime = null;
      }
    },
  },
};
</script>
